import useForceUpdate from "./hooks/useForceUpdate";
import useTheme from "./hooks/useTheme";
import Toasts from "./layers/Toasts";
import Main from "./layers/Main";
import darkTheme from "./themes/dark";
import lightTheme from "./themes/light";
import React from "react";
const layers = [
    React.createElement(Main, { key: "main" }),
    React.createElement(Toasts, { key: "toasts" })
];
const baseLength = layers.length;
let forceUpdate;
export default function AppLayers() {
    forceUpdate = useForceUpdate();
    const theme = useTheme() === "dark" ? darkTheme : lightTheme;
    return React.createElement("div", { style: {
            backgroundColor: theme.palette.background.default,
            display: "block",
            height: "100vh",
            width: "100%"
        } }, layers);
}
window.addEventListener("keydown", (ev) => {
    switch (ev.key) {
        case "Escape": {
            const layer = [...layers].reverse().find(e => typeof e.key === "string" && e.key.startsWith("layer-"));
            if (!layer)
                return;
            if (layer.props.close)
                layer.props.close();
            closeLayer(layer.key);
        }
    }
});
export function closeLayer(key) {
    const index = layers.findIndex(e => e.key === key);
    if (index === -1)
        return;
    layers.splice(index, 1);
    forceUpdate();
    if (layers.length === baseLength)
        document.body.style.overflow = "";
}
export function pushLayer(layer) {
    layers.push(layer);
    forceUpdate();
    if (layers.length === baseLength + 1)
        document.body.style.overflow = "hidden";
}
