/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "@emotion/react";
import useMobile from "../hooks/useMobile";
import darkTheme from "../themes/dark";
import useWindowSize from "../hooks/useWindowSize";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@mui/material";
export default function Modal(props) {
    const isMobile = useMobile();
    const windowSize = useWindowSize();
    return jsx("div", { css: {
            width: windowSize[0],
            height: windowSize[1],
            background: "rgba(0, 0, 0, 0.4)",
            position: "absolute",
            top: document.documentElement.scrollTop,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2147483646
        } },
        jsx("div", { css: Object.assign({ textAlign: "center" }, (isMobile ? {
                width: "100%",
                backgroundColor: darkTheme.palette.secondary.main,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            } : {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            })), onClick: () => {
                if (isMobile)
                    return;
                props === null || props === void 0 ? void 0 : props.close();
            } }, !isMobile ? jsx("div", { css: {
                borderRadius: "8px",
                backgroundColor: darkTheme.palette.secondary.main,
                padding: "20px"
            }, onClick: ev => {
                ev.stopPropagation();
            } }, props.children) : jsx("div", null,
            jsx("div", { css: {
                    position: "absolute",
                    right: 10,
                    top: 10
                } },
                jsx(Icon, { component: CloseIcon, onClick: () => {
                        props === null || props === void 0 ? void 0 : props.close();
                    } })),
            props.children)));
}
