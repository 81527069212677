import React from "react";
import ErrorBoundary from "./components/ErrorBoundary";
import Loading from "./components/Loading";
import darkTheme from "./themes/dark";
import { ThemeProvider } from "@mui/material/styles";
/*import useMediaQuery from "@mui/material/useMediaQuery"
import lightTheme from "./themes/light"*/
import CssBaseline from "@mui/material/CssBaseline";
import AppLayers from "./layers";
export default function App() {
    /*const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
    const theme = prefersDarkMode ? darkTheme : lightTheme*/
    return React.createElement(ErrorBoundary, null,
        React.createElement(ThemeProvider, { theme: darkTheme },
            React.createElement(CssBaseline, null),
            React.createElement(AppLayers, null)));
}
const pagesCache = {};
export function PageFetcher(props) {
    const [error, setError] = React.useState(null);
    const [hasLoaded, setLoaded] = React.useState(false);
    React.useEffect(() => {
        setLoaded(false);
        setError(null);
        let promise = pagesCache[props.page];
        if (promise) {
            if (promise instanceof Promise) {
                promise
                    .then(() => {
                    setLoaded(true);
                }).catch(err => {
                    setError(err);
                });
            }
            else {
                setLoaded(true);
            }
        }
        else {
            promise = (async () => {
                let mod = await import("./pages/" + props.page);
                if ((mod === null || mod === void 0 ? void 0 : mod.__esModule) && (mod === null || mod === void 0 ? void 0 : mod.default)) {
                    mod = mod.default;
                }
                pagesCache[props.page] = mod;
                setLoaded(true);
                return mod;
            })();
            pagesCache[props.page] = promise;
            promise.catch(err => {
                setError(err);
            });
        }
    }, [props.page]);
    React.useEffect(() => {
        if (error)
            console.error(error);
    }, [error]);
    if (error)
        return React.createElement(React.Fragment, null, "Check errors");
    if (!hasLoaded || !pagesCache[props.page])
        return React.createElement(Loading, null);
    return React.createElement(pagesCache[props.page]);
}
