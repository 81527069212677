/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "@emotion/react";
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import useMobile from "../hooks/useMobile";
import useViteConnect, { ViteConnectStateType } from "../hooks/useViteConnect";
import { closeLayer, pushLayer } from "../layers";
import Modal from "../layers/Modal";
import QRCode from "./QRCode";
import { Chip } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VitePlaceHeader from "../assets/viteplace-header-cropped.png";
export default function Navbar() {
    const history = useHistory();
    const isMobile = useMobile();
    return jsx(React.Fragment, null,
        jsx(AppBar, { position: "static", color: "secondary" },
            jsx(Toolbar, null,
                jsx("div", { css: {
                        flexGrow: 1,
                        display: "flex",
                        marginRight: 10
                    } },
                    jsx(Button, { color: "inherit", onClick: () => {
                            history.push("/");
                        }, css: {
                            marginRight: !isMobile ? 10 : 0
                        } },
                        jsx("img", { src: VitePlaceHeader, height: 40 }))),
                jsx("div", null,
                    jsx(ViteConnectInterface, null)))));
}
export function openViteConnect() {
    pushLayer(jsx(Modal, { key: "layer-viteconnect", close: () => {
            closeLayer("layer-viteconnect");
        } },
        jsx(ViteConnectQRCode, null)));
}
function ViteConnectInterface() {
    const [wallet, walletState] = useViteConnect();
    if (walletState === ViteConnectStateType.CONNECT) {
        // need to display the qrcode
        return jsx(Button, { color: "inherit", onClick: openViteConnect }, "Login");
    }
    else if (walletState === ViteConnectStateType.READY) {
        return jsx(Button, { color: "inherit", onClick: () => {
                pushLayer(jsx(Modal, { key: "layer-viteconnect-close", close: () => {
                        closeLayer("layer-viteconnect-close");
                    } },
                    jsx(ViteConnectClose, null)));
            } },
            jsx(Chip, { variant: "outlined", label: wallet.accounts[0], css: {
                    fontFamily: "'Overpass Mono', monospace",
                    textTransform: "lowercase"
                }, icon: jsx(AccountCircleIcon, null) }));
    }
    return null;
}
function ViteConnectClose() {
    const [wallet, walletState] = useViteConnect();
    if (walletState !== ViteConnectStateType.READY) {
        // means the user scanned it already
        setImmediate(() => {
            closeLayer("layer-viteconnect-close");
        });
        return null;
    }
    return jsx("div", null,
        jsx(Typography, { variant: "h6", css: {
                marginBottom: 20
            } }, "Are you sure you want to logout ?"),
        jsx(Button, { css: {
                marginRight: 10
            }, variant: "outlined", onClick: () => {
                wallet.vbInstance.killSession();
            } }, "Yes"),
        jsx(Button, { variant: "outlined", onClick: () => {
                closeLayer("layer-viteconnect-close");
            } }, "No"));
}
function ViteConnectQRCode() {
    const [wallet, walletState] = useViteConnect();
    if (walletState !== ViteConnectStateType.CONNECT) {
        // means the user scanned it already
        setImmediate(() => {
            closeLayer("layer-viteconnect");
        });
        return null;
    }
    return jsx("div", null,
        jsx(Typography, { variant: "h5", css: {
                marginBottom: 20
            } }, "Connect with ViteConnect!"),
        jsx(QRCode, { text: wallet.vbInstance.uri, css: {
                width: 400,
                height: 400
            } }));
}
