import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as React from "react";
import Navbar from "../components/Navbar";
import { PageFetcher } from "../app";
export default function Main() {
    return React.createElement(Router, null,
        React.createElement("div", null,
            React.createElement(Navbar, null),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/", exact: true },
                    React.createElement(PageFetcher, { page: "home/home" })),
                React.createElement(Route, { path: "/dist/index.html", exact: true },
                    React.createElement(PageFetcher, { page: "home/home" })),
                React.createElement(Route, { path: "/" },
                    React.createElement(PageFetcher, { page: "errors/404" })))));
}
