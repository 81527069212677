import * as React from "react";
import { v4 as uuid } from "uuid";
import useForceUpdate from "../hooks/useForceUpdate";
let forceUpdate = () => { };
const toasts = [];
export default function Toasts() {
    forceUpdate = useForceUpdate();
    if (toasts.length === 0)
        return null;
    return React.createElement("div", { className: "custom-toasts", style: {
            bottom: 80,
            zIndex: 2147483647
        }, ref: (div) => {
            if (!div)
                return;
            div.style.setProperty("left", `calc(50% - ${div.offsetWidth / 2}px)`);
        } }, toasts.map(e => e.elem));
}
window["showToast"] = showToast;
export function showToast(text, options = {
    timeout: 3000,
    type: null,
    icon: false
}) {
    const id = uuid();
    const classNames = ["custom-toast"];
    if (options.type)
        classNames.push("toast-" + options.type);
    if (options.type && options.icon)
        classNames.push("icon");
    const getElem = () => {
        return React.createElement("div", { key: id, className: classNames.join(" ") }, text);
    };
    toasts.push({
        elem: getElem(),
        id: id
    });
    forceUpdate();
    setTimeout(() => {
        classNames.push("closing");
        toasts.find(e => e.id === id).elem = getElem();
        forceUpdate();
        setTimeout(() => {
            toasts.splice(toasts.findIndex(e => e.id === id), 1);
            forceUpdate();
        }, 300);
    }, options.timeout || 3000);
}
