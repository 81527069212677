import Connector from "@vite/connector";
import { EEventEmitter } from "../events";
import { showToast } from "../layers/Toasts";
export const VITECONNECT_BRIDGE = "wss://viteconnect.thomiz.dev/";
export class ViteConnect extends EEventEmitter {
    constructor() {
        super();
        this.readyConnect = false;
        this.ready = false;
        this.accounts = null;
        this.setupVbInstance();
    }
    setupVbInstance() {
        this.vbInstance = new Connector({ bridge: VITECONNECT_BRIDGE });
        this.vbInstance.createSession()
            .then(() => {
            this.readyConnect = true;
            this.emit("newSession");
        });
        this.vbInstance.on("connect", (err, payload) => {
            console.log(`[VC]: Connect`, err, payload);
            this.accounts = payload.params[0].accounts;
            this.ready = true;
            this.readyConnect = false;
            this.emit("ready");
        });
        this.vbInstance.on("disconnect", (err, payload) => {
            var _a, _b;
            console.log(`[VC]: Disconnect`, err, payload);
            this.accounts = null;
            this.ready = false;
            this.vbInstance.stopBizHeartBeat();
            this.emit("close");
            showToast(`[ViteConnect]: ${((_b = (_a = payload.params) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message) || "Disconnected"}`, {
                type: "error",
                icon: true,
                timeout: 4000
            });
            this.setupVbInstance();
        });
    }
    async sendTransactionAsync(...args) {
        return new Promise((res, rej) => {
            this.on("close", () => {
                rej("Request aborted due to disconnect.");
            });
            this.vbInstance.sendCustomRequest({ method: "vite_signAndSendTx", params: args })
                .then(res, rej);
        });
    }
    async signMessageAsync(...args) {
        return new Promise((res, rej) => {
            this.on("close", () => {
                rej("Request aborted due to disconnect.");
            });
            this.vbInstance.sendCustomRequest({ method: "vite_signMessage", params: args })
                .then(res, rej);
        });
    }
}
export default new ViteConnect;
