import { ErrorBoundary } from "react-error-boundary";
import React from "react";
function ErrorFallback({ error, resetErrorBoundary }) {
    return React.createElement("div", { role: "alert" },
        React.createElement("p", null, "Something went wrong:"),
        React.createElement("pre", null, error.message),
        React.createElement("button", { onClick: resetErrorBoundary }, "Try again"));
}
export default function (props) {
    return React.createElement(ErrorBoundary, { FallbackComponent: ErrorFallback, onReset: () => {
            // reset the state of your app so the error doesn"t happen again
        } }, props.children);
}
