import { useEffect } from "react";
import wallet from "../wallet";
import useForceUpdate from "./useForceUpdate";
export var ViteConnectStateType;
(function (ViteConnectStateType) {
    ViteConnectStateType["CLOSED"] = "CLOSED";
    ViteConnectStateType["CONNECT"] = "CONNECT";
    ViteConnectStateType["READY"] = "READY";
})(ViteConnectStateType || (ViteConnectStateType = {}));
export default function useViteConnect() {
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        const forceUpdateListener = () => {
            forceUpdate();
        };
        wallet.on("ready", forceUpdateListener);
        wallet.on("close", forceUpdateListener);
        wallet.on("newSession", forceUpdateListener);
        return () => {
            wallet.off("ready", forceUpdateListener);
            wallet.off("close", forceUpdateListener);
            wallet.off("newSession", forceUpdateListener);
        };
    }, []);
    return [
        wallet,
        wallet.ready ?
            ViteConnectStateType.READY :
            wallet.readyConnect ?
                ViteConnectStateType.CONNECT :
                ViteConnectStateType.CLOSED
    ];
}
