import React from "react";
import ReactLoading from "react-loading";
import darkTheme from "../themes/dark";
export default function Loading() {
    return React.createElement("div", { style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: "200px"
        } },
        React.createElement(ReactLoading, { type: "spin", color: darkTheme.palette.primary.main, className: "margin-auto" }));
}
