// This file is basically an EventEmitter
// but with type checking for events
// without writing the thing 40 times
// Just write definitions in the constructor 
// and you're done.
import { EventEmitter } from "events";
export class EEventEmitter extends EventEmitter {
    on(event, listener) {
        return super.on(event, listener);
    }
    once(event, listener) {
        return super.once(event, listener);
    }
    off(event, listener) {
        return super.off(event, listener);
    }
    removeListener(event, listener) {
        return super.removeListener(event, listener);
    }
    removeAllListeners(event) {
        return super.removeAllListeners(event);
    }
    rawListeners(event) {
        return super.rawListeners(event);
    }
    addListener(event, listener) {
        return super.addListener(event, listener);
    }
    listenerCount(event) {
        return super.listenerCount(event);
    }
    emit(event, ...args) {
        return super.emit(event, ...args);
    }
    eventNames() {
        return super.eventNames();
    }
    listeners(event) {
        return super.listeners(event);
    }
    prependListener(event, listener) {
        return super.prependListener(event, listener);
    }
    prependOnceListener(event, listener) {
        return super.prependOnceListener(event, listener);
    }
}
export default new EEventEmitter();
